/* eslint-disable react/jsx-key */
import React , {Component} from 'react';
import { Divider,  CoreFeatureSection,
  CoreList ,ButtonLearnMoree,CoreWrapperNew, CustomList, TabsContainerV2, MenuListV2Main2, CoreFeatureSectionV2, ResourcesV2, RowWrapperV2, RowResponsiveWrapperV2, TextImagesContainer, PaddingContainer, CoreWrapperV2} from './style';
import { ImageUrl, imgPath } from '../../utils/constants';
import PropTypes from "prop-types";
import {MenuLinkss} from '../MainDashboardV3/styles';
import {connect} from 'react-redux';
import {subscribeNewsLetter} from '../../redux/actions/authActions';
import  testimonialData from './TestimonialData.json'
import {CompanyChallengesWrapper,ShowsImg, ResourcesWrapper,Resources,ShowsImgNew} from '../DashboardFitnessEducation/style';
import data from './WellnessResources.json';
import coreData from './ChallengesData.json';
import {WellBeing,ButtonGetStarted} from '../MainDashboardV3/styles'
import {Section, IntroTextContainer, PosterImage,WrapperContainer} from '../DashboardRecipes/style';
import {MainResources, RowWrapper, RowResponsiveWrapper} from '../WellnessPageV2/styles'
import step from './activitysteps.json';
import LazyImage from '../common/LazyImage/LazyImage';
import { ButtonWatchVideo } from '../CommunityRecognition/style';

class WellnessChallenges extends Component {

  constructor() {
    super();
    this.state = {
      seeMore: false,
      test: false,
      butttonIndex: 0,
      mobileViewStatus: window.innerWidth <= 500,
      active: '',
      selectedImage: '',
      selectedText: 'WHY USERS LOVE US',
      selectedTab: 0,
      selectedTabs: 0,
      selectedSubTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      challengesData: JSON.parse(JSON.stringify(coreData)),
      activitysteps: JSON.parse(JSON.stringify(step)),
      imageV: [step[0].step[0].imageV],
      image: [data[0].data[0].image],
      email: '',
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main],
      selectedChallengeData: coreData[0].data,
      selectedChallengeDataResp: coreData[0].data,
      currentDiv: [true, false, false, false, false, false],
      coreHead: [coreData[0].head, coreData[1].head, coreData[2].head],
      selectedCoreTab: 0,
      showtab: 1
    }
  }

  componentDidMount() {
    this.setState({ active: 'Insurance' })
  }

  TopPanelSection = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
        WELLBEING<br/>CHALLENGES
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/HomePageV2/Rectangle-green.png"}/>
        <p>
          Build a winning culture with challenges {this.state.mobileViewStatus ? '' : <br/>}
          that lead to happier, healthier and {this.state.mobileViewStatus ? '' : <br/>}
          more productive employees {this.state.mobileViewStatus ? '' : <br/>}
        </p>
        <div className="wrap">
          <a href='https://woliba.navattic.com/253k07wk' target='blank' style={{textDecoration:"none"}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center",color:"#9fc989",border:"2px solid #9fc989"}}>
            <svg width="21" height="24" viewBox="0 0 136 153" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M129.381 65.0255C138.206 70.1255 138.206 82.8753 129.381 87.9752L20.1667 151.086C11.3413 156.186 0.30957 149.812 0.30957 139.612L0.309577 13.3884C0.309577 3.18855 11.3413 -3.18634 20.1667 1.91359L129.381 65.0255Z" fill="#9fc989"/>
            </svg>
            <div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>  
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1} style={{marginLeft:"5%"}}>BOOK A DEMO</ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet={ImageUrl + "/images/DashboardChallenges/Challengebanner.png"} type="image/png"/>
          <LazyImage src={ImageUrl + "/images/DashboardChallenges/Challengebanner.png"} title="Poster Image"/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/DashboardChallenges/activity_challenge_mobile.png"} title="Poster mobile"/>
    </Section>
  );
  
  handleSubTabs = (index, data) => {
    this.setState({ selectedSubTab: index, selectedChallengeData: data });
  }
  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp })
  }

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp })
  }

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  }
  handleTab = (index) => {
    this.setState({ selectedTabs: index });
  }

  showList = (index) => {
    this.setState({ seeMore: !this.state.seeMore, test: !this.state.test, buttonIndex: index });
  };

  handleSubResponsiveTabs = (resIndex, data) => {
    const { currentDiv } = this.state;
    let array = currentDiv;
    array.forEach((data, index) => {
      array[index] = false;
    })
    array[resIndex] = true;
    this.setState({ selectedCoreTab: resIndex });
    this.setState({ currentDiv: array });
    this.setState({ selectedChallengeDataResp: data });
    if(resIndex===this.state.selectedCoreTab){
      this.setState({showtab: !this.state.showtab});
    }
  }
 

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "Classic",
        image: "/path/"
      },
      {
        tabName: "Survivor",
        image: "/path/"
      },
      {
        tabName: "Group",
        image: "/path/"
      }
    ];
    return (
      <CoreWrapperNew>
        <div>
          <div className="tab-header">
            <h2>{"Employee Wellness Challenges"}</h2>
            <p>We specialize in crafting challenges that get employees excited about embarking on a wellness journey.
            </p>
            <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
          </div>
          <div className="change">
            <TabsContainerV2>
              <MenuListV2Main2 >
                {TabArray.map((data, index) => (
                  <CustomList showTab={true} active={this.state.selectedSubTab === index} key={index}
                    onClick={() => this.handleSubTabs(index, coreData[index].data)}>
                    <MenuLinkss ><h3 style={{fontSize:'20px'}}>{data.tabName}</h3></MenuLinkss>
                  </CustomList>
                ))}
              </MenuListV2Main2>
            </TabsContainerV2>
          </div>
        </div>
        <div className="card-container">
          {this.state.selectedChallengeData.length > 0 && this.state.selectedChallengeData.map((row, index) => (
            <div className="card-data" key={index}>
              <LazyImage div={"image-container"} src={ImageUrl + `${row.img}`} />
              <div className="card-title">
                <h4>{row.head}</h4>
              </div>
              <div className="card-details">
                <p>{row.descLess}</p>
                {row.descDash && (<h3>{row.descDash}</h3>)}
                {row.descNew && (<h3>{row.descNew}</h3>)}
                {row.descNew && (<h3>{row.deshNew}</h3>)}
                {(this.state.test) && (index === this.state.buttonIndex) ? (<p>{row.descMore}</p>) : ''}
              </div>
              <div className="card-button">
                {row.descLess.length > 150 ?
                  <button onClick={() => this.showList(index)} >{(this.state.test) && (index === this.state.buttonIndex) ?
                    (<div style={{display:"flex"}}><span>Read Less</span><span> <LazyImage src={ImageUrl +"/images/DashboardChallenges/Arrow-back.png"}/></span></div>) : (<div style={{display:"flex"}}><span>Read More</span><span><LazyImage src={ImageUrl + "/images/DashboardChallenges/Arrow-forward.png"} /></span></div>)}</button>
                  : <span />}
              </div>
            </div>
          ))}
        </div>
        <div className="responsive-core">
          {TabArray.map((data, index) => (
            <CoreList active={this.state.selectedCoreTab === index && this.state.showtab? 1 : 0} key={index} onClick={() => this.handleSubResponsiveTabs(index, coreData[index].data)}>
              <div style={{alignItems:'center'}}>
                <h3>{data.tabName}</h3>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              {this.state.currentDiv[index] && this.state.showtab &&
                <div className="card-container-resp">
                  {this.state.selectedChallengeDataResp.length > 0 && this.state.selectedChallengeDataResp.map((row, index) => (
                    <div className="card-data-resp" key={index}>
                      <LazyImage div={"image-container-resp"} src={ImageUrl + `${row.img}`} />
                      <div className="card-title-resp">
                        <h4>{row.head}</h4>
                      </div>
                      <div className="card-details-resp">
                        <p>{row.descLess}</p>
                        {(this.state.test) && (index === this.state.buttonIndex) ? (<p>{row.descMore}</p>) : ''}
                      </div>
                      <div className="card-button-resp">
                        {row.descMore.length > 100 ?
                          <button onClick={() => this.showList(index)} >{this.state.test ? (<div style={{display:"flex"}}><span>Read Less</span><span> <LazyImage src={ImageUrl + "/images/DashboardChallenges/Arrow-back.png"} /></span></div>) : (<div style={{display:"flex"}}><span>Read More</span><span><LazyImage src={ImageUrl + "/images/DashboardChallenges/Arrow-forward.png"} /></span></div>)}</button>
                          : <span />}
                      </div>
                    </div>
                  ))}
                </div>
              }
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreList>
          ))}
        </div>
      </CoreWrapperNew>)
  };
  
  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row, index) =>
        (<MainResources key={index} color={row.color}>
          <Resources flexDirection={row.swapRow} col={row.color}>
            <div>
              <div />
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status} bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div><h3>{info.header}</h3></div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }
    </ResourcesWrapper>
  );
  companySection = () => ( 
    <CoreWrapperV2> 
      <div className="tab-header">
        <h2>Easily connect your Wearable Device</h2>
        <p>{"We know one device isn't made for everyone. We integrate with the"}<br/>
leading providers, covering 70% of the consumer wearable device market. </p>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
      <CompanyChallengesWrapper>
        <ShowsImg>
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Fitbit_logo.svg"} alt={"fitbit"}  />
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Apple_health.svg"} alt={"Apple_health"}  />
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Apple_Watch_official_logo.svg"} alt={"Apple_Watch_official_logo"}  />
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Garmin.svg"} alt={"fitbit"}  />
        </ShowsImg>
        <ShowsImgNew>
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Strava.svg"} alt={"Strava"}  />
          <LazyImage src={ImageUrl + "/images/fitnessEducation/withings.svg"} alt={"withings"}  />
          <LazyImage src={ImageUrl + "/images/fitnessEducation/Googlefit.svg"} alt={"Googlefit"}  />
        </ShowsImgNew>
      </CompanyChallengesWrapper>
    </CoreWrapperV2>
  ) ;
  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div>
        <h2 style={{ marginBottom: "0px" }}>Connect Teams Across The Organization With</h2>
        <h2 style={{ margin: "0px" }}>Customizable Challenges</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
    </CoreFeatureSection> 
  );


  middleImages = () => (
    <TextImagesContainer>
      <div className='text-wrapper'>
        <h2 className='top-text'>Woliba Employee Challenges</h2>
        <div className='lower-text'>Nurture and retain your top talent with timely rewards.</div>
        <LazyImage className="blue-linee" src={ImageUrl + "/images/blue-divider.png"}/>
      </div>
      <div className='box-wrapper'>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Create Custom Challenge.svg'}/>  
          <div className='box-first-text'>Create Custom <br/>Challenges</div>
          <div className='box-last-text'>Get employees moving with a challenge<br/> 
            customized to your company.</div>
        </div>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Particiapte_as _team.svg'}/>    
          <div className='box-first-text'>Participate As A Team,<br/> Group, Or Individual</div>
          <div className='box-last-text'>Easily choose how many<br/>
            employees to invite.</div>
        </div>
        <div className='first-box'>
          <LazyImage className='box-img' src={ImageUrl + '/images/Complete_on_steps_Distance.svg'}/> 
          <div className='box-first-text'>Compete On Steps,<br/> Distance, Or Minutes</div>
          <div className='box-last-text'>Choose whether to compete based<br/>
            on steps, distance or minutes.</div>
        </div>
      </div>
    </TextImagesContainer>
  )

  coreFeatureSectionV2 = () => (
    <CoreFeatureSectionV2>
      <div>
        <h2>Launch A Challenge In 3 Easy Steps</h2>
        <h3>HR teams use Woliba Rewards in combination with Fitness, Social, Wellness, Health Assessment<br/>
and Surveys to holistically engage employees.</h3>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>

      </div>
    </CoreFeatureSectionV2>
  );
  singleFeature = () => (
    <ResourcesWrapper>{
      this.state.activitysteps.length > 0 && this.state.activitysteps.map((row,index)=>
        (<MainResources key={index} color={row.color}>
          <ResourcesV2 flexDirection={row.swapRow} col={row.color} align={index%2 == 0? 'flex-start': 'flex-end'}>
            <div>
              <div>
                {/* <img src={`${imgPath}${row.icon}`}/>
                <div className={"title"}>{row.title}</div> */}
              </div>
              <div className="normal">
                {
                  row.step.map((info, rowVindex) => (
                    <RowWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleSteps(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapperV2>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.step.map((info, rowVindex) => (
                    <div>
                      <RowResponsiveWrapperV2 key={rowVindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleSteps(index, rowVindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapperV2>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.imageV[index]}/>

          </ResourcesV2>
        </MainResources>
        ))}
    </ResourcesWrapper>);
  handleSteps = (resVIndex, rowVIndex) => {
    const {activitysteps, imageV} = this.state;
    let array = activitysteps;
    activitysteps[resVIndex].step.forEach((info, index) => {
      array[resVIndex].step[index].status = info.status===1 && 0;
    })
    array[resVIndex].step[rowVIndex].status = 1;
    let images = imageV;
    images[resVIndex] = activitysteps[resVIndex].step[rowVIndex].imageV; 
    this.setState({activitysteps: array, imageV: images});
  }

  wellBeingSection = () => (
    <WellBeing>
      < div>
        <div>
          <p>Are you ready to <br/>
          create a culture of <br/>
          wellbeing?</p>
          <ButtonGetStarted  display={'block'} onClick={()=> this.props.history.push('/contact')}>GET STARTED</ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/footerImage.png"}/>

    </WellBeing>
  )

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if (email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  render() {
    return (
      <WrapperContainer>
        {this.TopPanelSection()}
        {this.middleImages()}
        <PaddingContainer>{this.coreFeatureSectionV2()}  </PaddingContainer>
        <PaddingContainer>{this.singleFeature()}  </PaddingContainer>
        {this.coreFeatureSection()}
        {this.multipleFeatures()}
        {this.companySection()}
        {this.renderMenuList()}
        {this.wellBeingSection()}
      </WrapperContainer>
    );
  }
}
WellnessChallenges.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});
export default connect(null, mapDispatchToProps)(WellnessChallenges);
